<template>
  <div class="mb-8">
    <CTypo tstyle="title3" class="mb-4">{{ $t('yourOrders') }}</CTypo>
    <CAlert v-if="successOrderId" variant="success" highlight>
      {{ $t('yourOrderWasSuccessful') }}
    </CAlert>
    <CAlert v-if="failedOrderId" variant="danger" highlight>
      {{ $t('yourOrderHasFailed') }}
      <template v-slot:links>
        <CButton
          class="flex-shrink-0"
          tabindex="-1"
          variant="tertiary"
          size="small"
          :loading="tryAgainLoading"
          @click="tryPaymentAgain"
        >
          {{ $t('tryAgain') }}
          <CIcon name="external-link-outline" size="20" class="ml-1" />
        </CButton>
      </template>
    </CAlert>
    <CTable
      v-if="!loading"
      :data="allOrders"
      box
      mobile-cards
      mobile-breakpoint="640px"
      :total="pagination.total || 0"
      paginated
      :per-page="perPage"
      v-bind="{ ...$attrs }"
      narrowed
      @page-change="onPageChange"
    >
      <o-table-column
        :label="$t('orderedAt')"
        width="110"
        v-slot="{ row }"
        field="createdAt"
      >
        {{ parseDate(row.createdAt) }}
      </o-table-column>
      <o-table-column
        :label="$t('merchantWastes.default')"
        width="180"
        v-slot="{ row }"
        field="containerOrder.merchantWaste.customName"
      >
        {{ row.containerOrder.merchantWaste.customName }}
      </o-table-column>
      <o-table-column
        :label="$t('container')"
        width="250"
        v-slot="{ row }"
        field="containerOrder.product.container.size"
      >
        {{ $n(row.containerOrder.product.container.size) }}m&sup3;
        {{ $t(`products.container.types.${row.containerOrder.product.container.type}`) }}
        <CContainerOpeningType 
          :container="row.containerOrder.product.container" 
          class="text-xs font-normal text-gray-700"
        />
      </o-table-column>
      <o-table-column
        :label="$t('deliveryDate')"
        width="100"
        sortable
        v-slot="{ row }"
        field="containerOrder.deliveryDate"
      >
        {{ parseDate(row.containerOrder.deliveryDate) }}
      </o-table-column>
      <o-table-column
        :label="$t('pickupDate')"
        width="100"
        sortable
        v-slot="{ row }"
        field="containerOrder.deliveryDate"
      >
        {{ parseDate(row.containerOrder.pickupDate) }}
      </o-table-column>
      <o-table-column
        :label="$t('address.deliveryAddress')"
        width="200"
        sortable
        v-slot="{ row }"
        field="deliveryAddress.postalCodeId"
      >
        {{ row.deliveryAddress.street }} {{ row.deliveryAddress.number }},<br>
        {{ row.deliveryAddress.location.postalCode }} {{ row.deliveryAddress.location.city }}
      </o-table-column>
      <o-table-column
        :label="$t('totalPrice')"
        width="110"
        sortable
        v-slot="{ row }"
        field="containerOrder.totalPrice"
      >
        {{ $n(toPriceInclTax(row.containerOrder.totalPrice), 'currency') }}
      </o-table-column>
      <o-table-column
        v-slot="{ row }"
        :label="$t('paymentStatus')"
        width="140"
        field="status"
      >
        <PaymentStatus
          :status="row.status"
          small
        />
      </o-table-column>
    </CTable>
    <CLoading v-else class="py-6" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { toDecimalPriceInclTax } from '@contimo/api/src/utils/integerPriceParser';
import { orders } from '@contimo/api/src/api';
import { GET_ORDERS } from '@/store/actionTypes';
import { ALL_ORDERS, ORDERS_NEXT_PAGE } from '@/store/gettersTypes';
import PaymentStatus from './PaymentStatus.vue';

export default {
  components: {
    PaymentStatus,
  },

  data: () => ({
    tryAgainLoading: false,
  }),

  computed: {
    ...mapGetters([ALL_ORDERS, ORDERS_NEXT_PAGE]),
    loading() {
      return this.$store.state.orders.loading;
    },
    pagination() {
      return this.$store.state.orders.pagination || {};
    },
    perPage() {
      return 20;
    },
    successOrderId() {
      return this.$route.query.successOrder;
    },
    failedOrderId() {
      return this.$route.query.failedOrder;
    },
  },

  created() {
    this.getOrders();
  },

  methods: {
    ...mapActions([GET_ORDERS]),
    toPriceInclTax(price) {
      return toDecimalPriceInclTax(price, 19);
    },
    parseDate(date) {
      return new Date(date).toLocaleDateString();
    },
    onPageChange(page) {
      this.getOrders({ page, perPage: this.perPage });
    },
    tryPaymentAgain() {
      this.tryAgainLoading = true;
      orders.show(this.failedOrderId).then(({ data }) => {
        this.tryAgainLoading = false;
        window.location.href = data.paymentPageUrl;
      });
    },
  },
};
</script>
