<template>
  <div>
    <CTypo tstyle="title3" class="mb-4">{{ $t('yourAddresses') }}</CTypo>
    <CTable
      v-if="!loading"
      :data="allAddresses"
      box
      mobile-cards
      mobile-breakpoint="640px"
      :total="pagination.total || 0"
      paginated
      :per-page="perPage"
      v-bind="{ ...$attrs }"
      narrowed
      @page-change="onPageChange"
    >
      <o-table-column
        :label="$t('address.type')"
        width="250"
        sortable
        v-slot="{ row }"
        field="type"
      >
        {{ $t(`address.types.${row.type}`) }}
      </o-table-column>
      <o-table-column
        :label="$t('address.street')"
        width="180"
        sortable
        v-slot="{ row }"
        field="street"
      >
        {{ row.street }} {{ row.number }}
      </o-table-column>
      <o-table-column
        :label="$t('address.postalCode')"
        width="180"
        sortable
        v-slot="{ row }"
        field="postalCodeId"
      >
        {{ row.postalCodeId }}
      </o-table-column>
      <o-table-column
        :label="$t('address.city')"
        width="180"
        sortable
        v-slot="{ row }"
        field="location.city"
      >
        {{ row.location.city }}
      </o-table-column>
    </CTable>
    <CLoading v-else class="py-6" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_ADDRESSES } from '@/store/actionTypes';
import { ALL_ADDRESSES } from '@/store/gettersTypes';

export default {
  computed: {
    ...mapGetters([ALL_ADDRESSES]),
    loading() {
      return this.$store.state.addresses.loading;
    },
    pagination() {
      return this.$store.state.addresses.pagination || {};
    },
    perPage() {
      return 5;
    },
  },

  created() {
    this.getAddresses();
  },

  methods: {
    ...mapActions([GET_ADDRESSES]),
    onPageChange(page) {
      this.getAddresses({ page, perPage: 10 });
    },
  },
};
</script>
