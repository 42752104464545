<!-- eslint-disable max-len -->
<template>
  <div
    :class="{
      'max-w-[420px]': isOrderView,
    }"
  >
    <CCard spacing>
      <div class="flex justify-between">
        <CTypo tstyle="title3">
          {{ $t('containerOrder.containerFor', {
            merchantWasteName: selectedMerchantWaste.customName,
          }) }}
        </CTypo>
        <CLink
          v-if="$route.path !== '/select-container'"
          class="font-semibold text-primary"
          :to="backLink"
        >
          {{ $t('changeOrderData') }}
        </CLink>
      </div>

      <CTypo :tstyle="!isOrderView ? 'subtitle' : 'body'">
        <template v-if="!selectedDeliveryAddress && !anonymousOrderDeliveryAddress">
          {{ $t('containerOrder.place', {
            postalCode: selectedDeliveryArea.location.postalCode,
            city: selectedDeliveryArea.location.city
          }) }}
        </template>
        <template v-else-if="selectedDeliveryAddressFull">
          <span
            v-html="$t('containerOrder.deliveryAddress', {
              postalCode: `${selectedDeliveryAddressFull.street} ${selectedDeliveryAddressFull.number}`,
              city: `${selectedDeliveryAddressFull.location.postalCode} ${selectedDeliveryAddressFull.location.city}`
            })"
          />
        </template>
        <template v-else-if="anonymousOrderDeliveryAddress">
          <span
            v-html="$t('containerOrder.deliveryAddress', {
              postalCode: `${anonymousOrderDeliveryAddress.street} ${anonymousOrderDeliveryAddress.number}`,
              city: `${anonymousOrderDeliveryAddress.postalCodeId.split('DE-')[1]}`
            })"
          />
        </template>
      </CTypo>

      <div class="mt-3">
        <CTypo v-if="!selectedDeliveryAddressFull && !anonymousOrderDeliveryAddress" class="inline-block mr-2">
          {{ $t('circa') }}
        </CTypo>
        <div v-if="isOrderView">
          <CTypo page class="mb-2">
            <p
              v-html="$t('containerOrder.summary', {
                selectedDeliveryDate: parseDate(selectedDeliveryDate),
                selectedPickupDate: parseDate(selectedPickupDate),
                containerSize: selectedContainer.size,
                containerType: $t(`products.container.types.${selectedContainer.type}`),
                waste: selectedMerchantWaste.customName,
              })"
            />
          </CTypo>
        </div>
        <LoadingSpinner
          v-if="storefrontContainerSelectionLoading"
          class="inline-block w-8 h-8 ml-6 my-2 mb-2"
        />
        <CTypo v-else class="inline-block" tstyle="largeTitle">
          {{ $n(toDecimalPriceInclTax(selectedContainer.priceCalculation.totalPrice), 'currency') }}
        </CTypo>
      </div>

      <CTypo v-if="!storefrontContainerSelectionLoading && !isOrderView" tstyle="footnote1" class="max-w-xs">
        {{ $t('containerOrder.inclusive', {
          tax: $n(
            toDecimalPrice(getTaxSumFromInt(selectedContainer.priceCalculation.totalPrice)),
            'currency'
          ),
          rental:
            $n(toDecimalPrice(selectedContainer.priceCalculation.durationPrice), 'currency'),
          freeRentalDays: selectedContainer
            .product
            .currentSellingPrice
            .containerSellingPrice
            .durationPriceAfter
        }) }}
      </CTypo>
      <CTypo v-else-if="!storefrontContainerSelectionLoading">
        {{ $t('containerOrder.inclusiveAlt', {
          tax: $n(
            toDecimalPrice(getTaxSumFromInt(selectedContainer.priceCalculation.totalPrice)),
            'currency'
          ),
          rental:
            $n(toDecimalPrice(selectedContainer.priceCalculation.durationPrice), 'currency'),
          freeRentalDays: selectedContainer
            .product
            .currentSellingPrice
            .containerSellingPrice
            .durationPriceAfter
        }) }}
      </CTypo>
      <CTypo v-if="!selectedDeliveryAddressFull && !anonymousOrderDeliveryAddress" tstyle="footnote1" class="max-w-xs mt-3">
        {{ $t('containerOrder.priceWithoutDeliveryAddressNote') }}
      </CTypo>
      <form class="mt-4" @submit.prevent="onSubmit(false)">
        <div v-if="!isOrderView" class="flex items-center">
          <CDatepicker
            v-model="selectedDeliveryDate"
            :disabled="$route.path !== '/select-container'"
            :placeholder="$t('deliveryDate')"
            icon="calendar"
            :focused-date="minDateDelivery"
            :min-date="minDateDelivery"
            :max-date="maxDate"
            :unselectable-days-of-week="unselectableDaysOfWeek"
            :unselectable-dates="unselectableDates"
            @input="onDeliveryDateInput"
            :mobile-native="false"
          />
          <div class="mb-4 ml-3">
            <DateTimeOutput :output="shop.fromDeliveryTime" />
          </div>
        </div>

        <div v-if="!isOrderView" class="flex items-center">
          <CDatepicker
            ref="pickupDatePicker"
            v-model="selectedPickupDate"
            :disabled="$route.path !== '/select-container'"
            :placeholder="$t('pickupDate')"
            icon="calendar"
            :focused-date="selectedPickupDate || minDatePickup"
            :min-date="minDatePickup"
            :max-date="maxDate"
            :unselectable-days-of-week="unselectableDaysOfWeek"
            :unselectable-dates="unselectableDates"
            :mobile-native="false"
          />
          <div class="mb-4 ml-3">
            <DateTimeOutput :output="shop.fromPickupTime" />
          </div>
        </div>
        <div v-if="isOrderView" class="max-w-sm">
          <CTextField
            v-model="customerNoteInput"
            :label="$t('customerNote')"
            type="textarea"
            rows="3"
          />
          <CCheckbox v-model="terms" required>
            <span v-html="$t('acceptTerms', { href: '/p/agb' })" />
          </CCheckbox>
          <CTypo tstyle="footnote2" class="text-gray-600 mb-4">
            {{ $t('acceptRevocationDenialDescription') }}
          </CTypo>
          <CCheckbox v-model="revocation" required>
            <span v-html="$t('acceptRevocationDenial', { href: '/p/widerruf' })" />
          </CCheckbox>
        </div>
        <CButton
          v-if="$route.path !== '/login'"
          native-type="submit"
          :disabled="
            !selectedDeliveryDate ||
              !selectedPickupDate ||
              (selectedContainer.isOverbooked && $route.path === '/select-container')
          "
          :loading="loading"
          variant="primary"
          class="w-full"
        >
          {{ $route.path === '/order' ? $t('orderNow') : $t('continue') }}
        </CButton>
      </form>
    </CCard>
    <Portal to="overlays">
      <PublicPlacementModal
        v-model="placementDialog"
        :content="shop.publicPlacementNotes"
        :submit-label="$t('continue')"
        @submit="onSubmit(true)"
      />
      <MerchantWasteNotesModal
        v-model="wasteNotesDialog"
        :content="selectedMerchantWaste.notes"
        :submit-label="$t('continue')"
        @submit="onSubmit(true)"
      />
    </Portal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import LoadingSpinner from '@contimo/ui/src/components/Loading/LoadingSpinner';
import {
  toDecimalPrice,
  getTaxSumFromInt,
  toDecimalPriceInclTax,
} from '@contimo/api/src/utils/integerPriceParser';
import {
  ADDRESS_CREATION,
  ANONYMOUS_ORDER,
  CUSTOMER_NOTE,
  DELIVERY_DATE,
  PICKUP_DATE,
  PUBLIC_HOLIDAYS,
  SELECTED_BILLING_ADDRESS,
  SELECTED_CONTAINER,
  SELECTED_DELIVERY_ADDRESS,
  SELECTED_DELIVERY_ADDRESS_FULL,
  SELECTED_DELIVERY_AREA,
  SELECTED_MERCHANT_WASTE,
  SHOP,
  STOREFRONT_CONTAINER_SELECTION_LOADING,
  THIS_USER,
} from '@/store/gettersTypes';
import { GET_ADDRESS, GET_CONTAINER_SELECTION } from '@/store/actionTypes';
import { WEEKDAYS } from '@/contants/time';
import { SET_CUSTOMER_NOTE, SET_DELIVERY_DATE, SET_PICKUP_DATE } from '@/store/mutationTypes';
import MerchantWasteNotesModal from './MerchantWasteNotesModal.vue';
import PublicPlacementModal from './PublicPlacementModal.vue';
import DateTimeOutput from './DateTimeOutput/index.vue';

export default {
  components: {
    LoadingSpinner,
    DateTimeOutput,
    PublicPlacementModal,
    MerchantWasteNotesModal,
  },
  data() {
    return {
      maxDate: this.$DateTime.now().plus({ years: 2 }).toJSDate(),
      loading: false,
      terms: false,
      revocation: false,
      placementDialog: false,
      wasteNotesDialog: false,
    };
  },

  computed: {
    ...mapGetters([
      SELECTED_CONTAINER,
      SELECTED_DELIVERY_AREA,
      SELECTED_MERCHANT_WASTE,
      STOREFRONT_CONTAINER_SELECTION_LOADING,
      SHOP,
      PUBLIC_HOLIDAYS,
      DELIVERY_DATE,
      PICKUP_DATE,
      THIS_USER,
      SELECTED_DELIVERY_ADDRESS,
      SELECTED_BILLING_ADDRESS,
      SELECTED_DELIVERY_ADDRESS,
      SELECTED_DELIVERY_ADDRESS_FULL,
      CUSTOMER_NOTE,
      ADDRESS_CREATION,
      ANONYMOUS_ORDER,
    ]),
    minDateDelivery() {
      return this.$DateTime
        .now()
        .plus({ days: this.shop.minDaysBetweenOrderAndDelivery })
        .toJSDate();
    },
    minDatePickup() {
      if (this.selectedDeliveryDate) {
        return this.$DateTime.fromJSDate(this.selectedDeliveryDate).plus({ days: 1 }).toJSDate();
      }
      return this.$DateTime.fromJSDate(this.minDateDelivery).plus({ days: 1 }).toJSDate();
    },
    unselectableDaysOfWeek() {
      const days = [];
      WEEKDAYS.forEach((weekday, i) => {
        if (!this.shop.deliveryDays.some((deliveryDay) => deliveryDay.name === weekday)) {
          days.push(i);
        }
      });

      return days;
    },
    unselectableDates() {
      return this.publicHolidays.map((holiday) => this.$DateTime.fromISO(holiday.date).toJSDate());
    },
    selectedDeliveryDate: {
      get() {
        return this.deliveryDate;
      },
      set(value) {
        if (value) {
          const deliveryTimeParsed = this.$DateTime.fromFormat(
            this.mapTimeString(this.shop.fromDeliveryTime),
            'hh:mm',
          );
          value = this.$DateTime
            .fromJSDate(value)
            .plus({ hours: deliveryTimeParsed.hour, minutes: deliveryTimeParsed.minutes })
            .toJSDate();
        }

        this.setDeliveryDate(value);
      },
    },
    selectedPickupDate: {
      get() {
        return this.pickupDate;
      },
      set(value) {
        if (value) {
          const pickupTimeParsed = this.$DateTime.fromFormat(
            this.mapTimeString(this.shop.fromPickupTime),
            'hh:mm',
          );
          value = this.$DateTime
            .fromJSDate(value)
            .plus({ hours: pickupTimeParsed.hour, minutes: pickupTimeParsed.minutes })
            .toJSDate();
        }
        this.setPickupDate(value);
      },
    },
    backLink() {
      // eslint-disable-next-line max-len
      return `/select-container?merchantWasteId=${this.selectedMerchantWaste.id}&deliveryAreaId=${this.selectedDeliveryArea.id}`;
    },
    customerNoteInput: {
      get() {
        return this.customerNote;
      },
      set(value) {
        this.setCustomerNote(value);
      },
    },
    isOrderView() {
      return this.$route.path === '/order';
    },

    anonymousOrderDeliveryAddress() {
      if (
        this.anonymousOrder?.deliveryAddress &&
        this.anonymousOrder?.deliveryAddress.street &&
        this.anonymousOrder?.deliveryAddress.number &&
        this.anonymousOrder?.deliveryAddress.postalCodeId
      ) {
        return this.anonymousOrder.deliveryAddress;
      }
      return null;
    },
  },

  watch: {
    selectedDeliveryDate() {
      if (
        this.$DateTime.fromJSDate(this.selectedDeliveryDate).plus({ days: 1 }) >
        this.$DateTime.fromJSDate(this.selectedPickupDate)
      ) {
        this.setPickupDate(null);
      }

      this.getContainerSelection();
    },
    selectedPickupDate() {
      this.getContainerSelection();
    },
    selectedDeliveryAddress(newVal) {
      if (newVal) {
        this.getAddress(newVal);
        this.getContainerSelection();
      }
    },
  },

  created() {
    if (['/address', '/order'].includes(this.$route.path)) {
      this.getContainerSelection();
      if (this.selectedDeliveryAddress) {
        this.getAddress(this.selectedDeliveryAddress);
      }
    }
  },

  methods: {
    ...mapActions([GET_CONTAINER_SELECTION, GET_ADDRESS]),
    ...mapMutations([SET_DELIVERY_DATE, SET_PICKUP_DATE, SET_CUSTOMER_NOTE]),
    onSubmit(force = false) {
      if (!force) {
        if (
          this.$route.path === '/address' &&
          this.shop.publicPlacementNotes &&
          this.shop.publicPlacementNotes.length > 0 &&
          this.$store.state.orders.publicPropertyPlacement
        ) {
          this.placementDialog = true;
          return true;
        }
        if (
          this.$route.path === '/select-container' &&
          this.selectedMerchantWaste.notes &&
          this.selectedMerchantWaste.notes.length > 0
        ) {
          this.wasteNotesDialog = true;
          return true;
        }
      }
      this.$emit('onSubmit');
      if (this.$route.path === '/order') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 10000);
      }
      return true;
    },
    toDecimalPrice,
    toDecimalPriceInclTax(price) {
      return toDecimalPriceInclTax(price, 19);
    },
    getTaxSumFromInt(price) {
      return getTaxSumFromInt(price, 19);
    },
    onDeliveryDateInput() {
      this.$nextTick(() => {
        if (!this.selectedPickupDate) {
          this.$refs.pickupDatePicker.toggle();
        }
      });
    },
    parseDate(date) {
      return new Date(date).toLocaleDateString();
    },
    mapTimeString(val) {
      if (val === 'allDay' || val === 'morning') {
        return '10:00';
      }
      if (val === 'inTheAfternoon') {
        return '15:00';
      }
      return val;
    },
  },
};
</script>
