var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CTypo',{staticClass:"mb-4",attrs:{"tstyle":"title3"}},[_vm._v(_vm._s(_vm.$t('yourAddresses')))]),(!_vm.loading)?_c('CTable',_vm._b({attrs:{"data":_vm.allAddresses,"box":"","mobile-cards":"","mobile-breakpoint":"640px","total":_vm.pagination.total || 0,"paginated":"","per-page":_vm.perPage,"narrowed":""},on:{"page-change":_vm.onPageChange}},'CTable',Object.assign({}, _vm.$attrs),false),[_c('o-table-column',{attrs:{"label":_vm.$t('address.type'),"width":"250","sortable":"","field":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$t(("address.types." + (row.type))))+" ")]}}],null,false,3356478313)}),_c('o-table-column',{attrs:{"label":_vm.$t('address.street'),"width":"180","sortable":"","field":"street"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.street)+" "+_vm._s(row.number)+" ")]}}],null,false,951854580)}),_c('o-table-column',{attrs:{"label":_vm.$t('address.postalCode'),"width":"180","sortable":"","field":"postalCodeId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.postalCodeId)+" ")]}}],null,false,3178738186)}),_c('o-table-column',{attrs:{"label":_vm.$t('address.city'),"width":"180","sortable":"","field":"location.city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.location.city)+" ")]}}],null,false,59857451)})],1):_c('CLoading',{staticClass:"py-6"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }