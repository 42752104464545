<template>
  <div>
    <Login v-if="mode === 'login'" @changeAuthMode="changeMode('registration')">
      {{ loginLabel }}
    </Login>
    <Registration v-else @changeAuthMode="changeMode('login')">
      {{ signupLabel }}
    </Registration>
  </div>
</template>

<script>
import Login from './Auth/Login.vue';
import Registration from './Auth/Registration.vue';

export default {
  components: {
    Login,
    Registration,
  },

  props: {
    loginLabel: {
      type: String,
      default: 'Login',
    },
    signupLabel: {
      type: String,
      default: 'Registrieren',
    },
  },

  data() {
    return {
      mode: 'login',
    };
  },

  methods: {
    changeMode(mode) {
      this.mode = mode;
    },
  },
};
</script>
