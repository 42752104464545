<template>
  <div class="flex justify-between items-center mb-6">
    <h3 class="text-2xl font-bold">
      {{ $t('welcomeUser', { name }) }}
    </h3>
    <CButton @click="$emit('logout')">{{ $t('logout') }}</CButton>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
