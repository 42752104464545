<template>
  <div>
    <div v-if="isGeneralTimeString">{{ $t(output) }}</div>
    <div v-else>{{ $t('from') }} {{ output }}</div>
  </div>
</template>

<script>
export default {
  props: {
    output: {
      type: String,
    },
  },

  computed: {
    isGeneralTimeString() {
      return (
        this.output === 'allDay' || this.output === 'morning' || this.output === 'inTheAfternoon'
      );
    },
  },
};
</script>