var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-8"},[_c('CTypo',{staticClass:"mb-4",attrs:{"tstyle":"title3"}},[_vm._v(_vm._s(_vm.$t('yourOrders')))]),(_vm.successOrderId)?_c('CAlert',{attrs:{"variant":"success","highlight":""}},[_vm._v(" "+_vm._s(_vm.$t('yourOrderWasSuccessful'))+" ")]):_vm._e(),(_vm.failedOrderId)?_c('CAlert',{attrs:{"variant":"danger","highlight":""},scopedSlots:_vm._u([{key:"links",fn:function(){return [_c('CButton',{staticClass:"flex-shrink-0",attrs:{"tabindex":"-1","variant":"tertiary","size":"small","loading":_vm.tryAgainLoading},on:{"click":_vm.tryPaymentAgain}},[_vm._v(" "+_vm._s(_vm.$t('tryAgain'))+" "),_c('CIcon',{staticClass:"ml-1",attrs:{"name":"external-link-outline","size":"20"}})],1)]},proxy:true}],null,false,1131287610)},[_vm._v(" "+_vm._s(_vm.$t('yourOrderHasFailed'))+" ")]):_vm._e(),(!_vm.loading)?_c('CTable',_vm._b({attrs:{"data":_vm.allOrders,"box":"","mobile-cards":"","mobile-breakpoint":"640px","total":_vm.pagination.total || 0,"paginated":"","per-page":_vm.perPage,"narrowed":""},on:{"page-change":_vm.onPageChange}},'CTable',Object.assign({}, _vm.$attrs),false),[_c('o-table-column',{attrs:{"label":_vm.$t('orderedAt'),"width":"110","field":"createdAt"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.parseDate(row.createdAt))+" ")]}}],null,false,1041675306)}),_c('o-table-column',{attrs:{"label":_vm.$t('merchantWastes.default'),"width":"180","field":"containerOrder.merchantWaste.customName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.containerOrder.merchantWaste.customName)+" ")]}}],null,false,835410268)}),_c('o-table-column',{attrs:{"label":_vm.$t('container'),"width":"250","field":"containerOrder.product.container.size"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$n(row.containerOrder.product.container.size))+"m³ "+_vm._s(_vm.$t(("products.container.types." + (row.containerOrder.product.container.type))))+" "),_c('CContainerOpeningType',{staticClass:"text-xs font-normal text-gray-700",attrs:{"container":row.containerOrder.product.container}})]}}],null,false,172180949)}),_c('o-table-column',{attrs:{"label":_vm.$t('deliveryDate'),"width":"100","sortable":"","field":"containerOrder.deliveryDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.parseDate(row.containerOrder.deliveryDate))+" ")]}}],null,false,1613940528)}),_c('o-table-column',{attrs:{"label":_vm.$t('pickupDate'),"width":"100","sortable":"","field":"containerOrder.deliveryDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.parseDate(row.containerOrder.pickupDate))+" ")]}}],null,false,1432709304)}),_c('o-table-column',{attrs:{"label":_vm.$t('address.deliveryAddress'),"width":"200","sortable":"","field":"deliveryAddress.postalCodeId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.deliveryAddress.street)+" "+_vm._s(row.deliveryAddress.number)+","),_c('br'),_vm._v(" "+_vm._s(row.deliveryAddress.location.postalCode)+" "+_vm._s(row.deliveryAddress.location.city)+" ")]}}],null,false,41093346)}),_c('o-table-column',{attrs:{"label":_vm.$t('totalPrice'),"width":"110","sortable":"","field":"containerOrder.totalPrice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$n(_vm.toPriceInclTax(row.containerOrder.totalPrice), 'currency'))+" ")]}}],null,false,1407576677)}),_c('o-table-column',{attrs:{"label":_vm.$t('paymentStatus'),"width":"140","field":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('PaymentStatus',{attrs:{"status":row.status,"small":""}})]}}],null,false,3001752556)})],1):_c('CLoading',{staticClass:"py-6"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }