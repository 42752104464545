<template>
  <div>
    <form
      class="w-full max-w-sm mx-auto px-4"
      @submit.prevent="submitRegistration"
    >
      <CTypo el="div" tstyle="title1" class="mb-6 flex items-center">
        <slot /><hr class="ml-4 flex flex-1">
      </CTypo>

      <CTextField
        v-model="signupData.firstName"
        :label="$t('firstName')"
        autocomplete="given-name"
        v-autofocus
        required
      />

      <CTextField
        v-model="signupData.lastName"
        :label="$t('lastName')"
        autocomplete="family-name"
        required
      />

      <CTextField
        v-model="signupData.email"
        :label="$t('email')"
        type="email"
        autocomplete="email"
        required
        @input="emailAlreadyUsed = false"
      />

      <CTextField
        v-model="signupData.phone"
        :label="$t('phone')"
        type="tel"
        autocomplete="tel"
        required
      />

      <CPasswordInputs ref="passwordInputs" v-model="passwordInputs" />

      <CMessage v-if="emailAlreadyUsed" variant="danger">
        {{ $t('emailAlreadyUsed') }}
      </CMessage>
      <CValidationFailure :error-fields-response="errorFieldsResponse" />

      <CCheckbox v-model="terms" required>
        <span v-html="$t('acceptTerms', { href: '/p/agb' })" />
      </CCheckbox>
      <CCheckbox v-model="privacy" required>
        <span v-html="$t('acceptPrivacy', { href: '/p/datenschutz' })" />
      </CCheckbox>

      <CButton
        native-type="submit"
        class="w-full mt-3"
        variant="primary"
        :loading="loading"
      >
        {{ $t('signup') }}
      </CButton>

      <CDivider class="my-6" />
      <CButton class="w-full" @click="$emit('changeAuthMode')">{{ $t('goBackToLogin') }}</CButton>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SIGNUP_CUSTOMER, LOGIN } from '@/store/actionTypes';
import { ROUTE_AFTER_LOGIN } from '@/store/gettersTypes';

export default {
  data() {
    return {
      signupData: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        termsVersion: '20210524',
        type: 'customer',
        password: '',
        terms: 1,
        privacy: 1,
      },
      passwordInputs: {
        password: '',
        passwordRepeat: '',
      },
      emailAlreadyUsed: false,
      errorFieldsResponse: null,
      loading: false,
      terms: false,
      privacy: false,
    };
  },

  computed: {
    ...mapGetters([ROUTE_AFTER_LOGIN]),
  },

  watch: {
    signupData: {
      handler() {
        this.errorFieldsResponse = null;
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions([SIGNUP_CUSTOMER, LOGIN]),
    async submitRegistration() {
      const valid = this.$refs.passwordInputs.validate();
      if (valid) {
        this.signupData.password = this.passwordInputs.password;
        try {
          this.loading = true;
          await this.signupCustomer(this.signupData);
          await this.login({ email: this.signupData.email, password: this.signupData.password });
          this.$router.push({
            path: this.routeAfterLogin,
            query: this.$route.query,
          });
          this.loading = false;
        } catch (e) {
          if (e.response.data) {
            const errCode = e.response.data.code;
            if (errCode === 'E_ALREADY_INVITED' || errCode === 'E_EMAIL_ALREADY_USED') {
              this.emailAlreadyUsed = true;
            }
            if (errCode === 'E_VALIDATION_FAILURE') {
              this.errorFieldsResponse = e.response;
            }
          }
          this.loading = false;
          console.log(e);
        }
      }
    },
  },
};
</script>
