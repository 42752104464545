<template>
  <CModal
    v-if="content && content.length > 0"
    :show="value"
    variant="dialog"
    @close="$emit('input', false)"
    @submit="$emit('input', false); $emit('submit')"
  >
    <template v-slot:header>
      <CTypo tstyle="title3">{{ $t('merchantWastes.notes') }}</CTypo>
    </template>
    <p v-if="content" class="prose-sm" v-html="content" />
    <template v-slot:submit>
      {{ submitLabel }}
    </template>
  </CModal>
</template>

<script>
export default {
  props: {
    submitLabel: {
      type: String,
      default: 'ok',
    },
    content: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
