<template>
  <div class="flex flex-col justify-center">
    <form
      class="w-full max-w-sm mx-auto px-4"
      @submit.prevent="submitLogin"
    >
      <CTypo el="div" tstyle="title1" class="mb-6 flex items-center">
        <slot /><hr class="ml-4 flex flex-1">
      </CTypo>
      <CTextField
        v-model="email"
        type="email"
        :label="$t('email')"
        required
      />
      <CTextField
        v-model="password"
        ref="passwordField"
        type="password"
        :label="$t('password')"
        :variant="loginError ? 'danger' : 'default'"
        required
        password-reveal
        @focus="onPasswordFocus"
      >
        <template v-slot:message>
          <div v-if="loginError">
            <span>
              {{ $t('errorMessages.emailAndOrPasswordIsWrong') }}
            </span>
            <CLink
              to="/request-password-reset"
              class="text-gray-600"
              tabindex="-1"
              small
            >
              {{ $t('passwordForgotten') }}
            </CLink>
          </div>
        </template>
      </CTextField>
      <CLink
        v-if="!loginError"
        to="/request-password-reset"
        class="text-gray-600 mb-4"
        tabindex="-1"
        :target="openInNewTab ? '_blank' : '_self'"
      >
        {{ $t('passwordForgotten') }}
      </CLink>
      <CButton
        native-type="submit"
        class="w-full mt-3"
        variant="primary"
        :loading="isLoginLoading"
      >
        {{ $t('login') }} {{ $route.path === '/login' ? $t('andNext') : '' }}
      </CButton>
    </form>
    <div class="w-full max-w-sm mx-auto px-4">
      <CDivider class="my-6">
        {{ $t('doNotHaveAnAccount') }}
      </CDivider>
      <CButton @click="$emit('changeAuthMode')" class="w-full">
        {{ $t('signupNow') }}
      </CButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { orders } from '@contimo/api/src/api';
import { IS_LOGIN_LOADING, ROUTE_AFTER_LOGIN } from '@/store/gettersTypes';
import { SET_NEXT_ROUTE } from '@/store/mutationTypes';
import { CANCEL_MY_ORDERS } from '@/store/actionTypes';

export default {
  data: () => ({
    email: null,
    password: null,
    loginError: false,
  }),

  computed: {
    ...mapGetters([IS_LOGIN_LOADING, ROUTE_AFTER_LOGIN]),
    openInNewTab() {
      return this.$route.fullPath.includes('/login');
    },
  },

  methods: {
    ...mapActions([CANCEL_MY_ORDERS]),
    ...mapMutations([SET_NEXT_ROUTE]),
    submitLogin() {
      this.loginError = false;
      this.$store
        .dispatch('login', { email: this.email, password: this.password })
        .then(() => {
          setTimeout(async () => {
            await orders.cancelMyOrders();

            this.email = null;
            this.password = null;

            if (this.routeAfterLogin) {
              this.$router.push({
                path: this.routeAfterLogin,
                query: this.$route.query,
              });
            } else {
              this.$router.push('/#');
            }

            this.setNextRoute(null);
          }, 300);
        })
        .catch((err) => {
          console.log(err);
          this.loginError = true;
          this.$refs.passwordField.blur();
          this.password = null;
        });
    },
    onPasswordFocus() {
      if (this.loginError) {
        this.loginError = false;
      }
    },
  },
};
</script>
